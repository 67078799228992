(<template>
  <article class="notification__item">
    <div>
      <p class="notification__item-title">{{ notification.name }}</p>
      <p class="notification__item-desc">{{ notification.description }}</p>
    </div>
    <div class="notification__togglers-wrapper">
      <div v-for="existingPlatform in existingPlatforms"
           :key="existingPlatform.name"
           class="notification__toggler">
        <div v-for="(platform, index) in getFilteredPlatforms(notification.availablePlatforms, existingPlatform.name)"
             :key="index">
          <p class="notification__toggler-name">{{ existingPlatform.text }}</p>
          <sk-toggler :disabled="isDisabled(platform)"
                      :preselected-value="isChecked(platform)"
                      :item-value="platform"
                      class="notification__sk-toggler"
                      @togglerchanged="catchToggler" />
        </div>
      </div>
    </div>
  </article>
</template>)

<script>
  import {mapMutations} from 'vuex';

  export default {
    props: {
      notification: {
        type: Object,
        default() {
          return {};
        }
      },
      existingPlatforms: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      ...mapMutations('TTNotifPreferencesStore', ['setChangedPreference']),
      isChecked(platform) {
        const turnedOnPlatforms = this.notification.turnedOnPlatforms || [];

        return turnedOnPlatforms.some((enablePlatform) => enablePlatform == platform);
      },
      isDisabled(platform) {
        const notEditablePlatforms = this.notification.notEditablePlatforms || [];

        return notEditablePlatforms.some((notEditablePlatform) => notEditablePlatform == platform);
      },
      getFilteredPlatforms(list = [], name) {
        return list.filter((item) => item === name) || [];
      },
      catchToggler(value, name) {
        this.setChangedPreference({key: this.notification.key, value, name});
        this.$emit('changesetting');
      }
    }
  };
</script>

<style scoped>
  .notification__item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .notification__item-title {
    padding-bottom: 3px;
    font-weight: bold;
    font-size: 14px;
  }

  .notification__item-desc {
    margin-right: 35px;
    color: #000;
    font-size: 12px;
  }

  .notification__togglers-wrapper {
    display: flex;
    margin-top: 20px;
  }

  .notification__toggler {
    width: 75px;
  }

  .notification__toggler-name {
    display: none;
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
  }

  .notification__sk-toggler {
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    .notification__item {
      flex-wrap: wrap;
    }

    .notification__togglers-wrapper {
      width: 100%;
      margin: 15px -15px 0;
    }

    .notification__toggler-name {
      display: block;
    }
  }
</style>
