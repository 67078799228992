(<template>
  <page-data-section class="notifications">
    <sk-note v-if="userIsInterpreter"
             :text="infoMessageText"
             class="notifications__info-note" />
    <div class="page-data-section__heading">
      <h3 class="page-data-section__title"
          v-html="sectionTitle"></h3>
      <div class="page-data-section__description"
           v-html="sectionDescription"></div>
    </div>
    <div class="notification__content-wrapper">
      <div class="notification__col-names">
        <p v-for="existingPlatform in existingPlatforms"
           :key="existingPlatform.name"
           class="notification__col-name">{{ existingPlatform.text }}</p>
      </div>
      <div class="notification__list">
        <notification-setting v-for="notification in filteredNotifications"
                              :key="notification.key"
                              :notification="notification"
                              :existing-platforms="existingPlatforms"
                              @changesetting="changeSetting" />
      </div>
    </div>
    <sk-note :text="messageText" />
  </page-data-section>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import NotificationSetting from '@/components/preferences/notification_settings/NotificationSetting';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';

  export default {
    asyncData({store}) {
      let promisesArr = '';
      if (!store.state.TTNotifPreferencesStore.preferences) {
        promisesArr ? promisesArr.push(store.dispatch('TTNotifPreferencesStore/fetchPreferences')) : promisesArr = [store.dispatch('TTNotifPreferencesStore/fetchPreferences')];
      }
      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'page-data-section': PageDataSection,
      'notification-setting': NotificationSetting
    },
    data() {
      return {
        androidAppLink: this.$getString('app', 'androidAppLink'),
        iosAppLink: this.$getString('app', 'iosAppLink')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsInterpreter']),
      ...mapState('TTNotifPreferencesStore', {
        notifications: (state) => state.preferences
      }),
      currentPage() { return this.$route.name; },
      filteredNotifications() {
        if (this.userIsInterpreter) {
          switch (this.currentPage) {
            case 'ServerTranslationNotifications':
              return this.notifications ? this.notifications?.filter((noti) => noti.tabName === 'translator') : [];
            case 'ServerInterpretationNotifications':
              return this.notifications ? this.notifications?.filter((noti) => noti.tabName === 'interpreter') : [];
          }
        } else {
          return this.notifications;
        }
      },
      existingPlatforms() {
        const platforms = [
          {name: 'email', text: this.$gettext('E-mail')},
          {name: 'push', text: this.$gettext('Push')}
        ];
        if (!this.userIsInterpreter) platforms.push({name: 'sms', text: this.$gettext('SMS')});
        return platforms;
      },
      sectionTitle() {
        if (this.userIsInterpreter) {
          switch (this.currentPage) {
            case 'ServerTranslationNotifications':
              return this.$gettext('Translation notification settings');
            case 'ServerInterpretationNotifications':
              return this.$gettext('Interpretation notification settings');
          }
        } else {
          return this.$gettext('Notification settings');
        }
      },
      sectionDescription() { return this.$gettext('On this page you are able to turn on and off your notifications.'); },
      phoneSettingsText() {
        return this.$gettext('To receive SMS notifications you need to add your mobile number under <button class="js-profile-link notification__link">My Profile.</button>');
      },
      interMessageText() {
        return this.$gettext('It is your responsibility as a professional to manage your time according to each assignment you have accepted.');
      },
      buyerMessageText() {
        let installAppText = '';
        const baseRoute = this.$router.currentRoute.path.replace('preferences/notifications', '');
        const appAndroidPath = `${baseRoute}app_android`;

        if (this.androidAppLink && this.iosAppLink) {
          const template = this.$gettext('To be able to receive push notifications you need to download the mobile app on <a class="notification__link" href="%{android}" target="_blank">Google Play</a> or on the <a class="notification__link" href="%{ios}" target="_blank">Apple AppStore</a>.');
          installAppText = this.$gettextInterpolate(template, {ios: this.iosAppLink, android: this.androidAppLink});
        } else {
          const template = this.$gettext('To be able to receive push notifications you need to download the mobile app on <a class="notification__link" href="%{android}" target="_blank">Salita android app</a> or on the <a class="notification__link" href="%{ios}" target="_blank">Apple AppStore</a>.');
          installAppText = this.$gettextInterpolate(template, {ios: this.iosAppLink, android: appAndroidPath});
        }

        return `${installAppText} ${this.phoneSettingsText}`;
      },
      messageText() {
        return this.userIsInterpreter ? this.interMessageText : this.buyerMessageText;
      },
      infoMessageText() {
        const template = this.$gettext('<b>Important:</b> %{domain} has turned off automated SMS invitations.<br>You will receive automated invitations only by email and push notifications.');

        return this.$gettextInterpolate(template, {
          domain: this.$getString('domainData', 'platformName')
        });
      }
    },
    watch: {
      $route(newVal) {
        if (newVal.params.lang) {
          this.refetchData();
        }
      }
    },
    methods: {
      changeSetting() {
        this.$store.dispatch('TTNotifPreferencesStore/savePreferences', this.notifications).then(() => {
        }).catch((error) => {
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        });
      },
      goToProfilePage() {
        this.$router.push(this.$makeRoute({name: 'BuyerUserProfileEdit', params: {id: this.userUid}}));
      },
      refetchData() {
        this.$store.commit('TTNotifPreferencesStore/clearStore');
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    mounted() {
      const linkElement = document.querySelector('.js-profile-link');
      if (linkElement) {
        linkElement.addEventListener('click', this.goToProfilePage);
      }
    },
    beforeDestroy() {
      this.$store.commit('TTNotifPreferencesStore/clearStore');
    }
  };
</script>

<style>
  .notification__link {
    text-decoration: underline;
  }
</style>

<style scoped>
  .notification__content-wrapper {
    position: relative;
    margin-right: 40px;
  }

  .notifications__info-note {
    margin-bottom: 20px;
  }

  .notification__col-names {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .notification__col-name {
    min-width: 75px;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
  }

  @media (max-width: 767px) {
    .notification__content-wrapper {
      margin: 0;
    }

    .notification__col-names {
      display: none;
    }
  }
</style>
